import React from "react";
import { graphql } from "gatsby";

import Layout from "../../components/layout";
import SmallBanner from "../../components/smallBanner";
import LastThreeRecruitment from "../../components/lastThreeRecruitment";

const Recruitment = ({ data: { lastRecruitment } }) => {
	const lang = "de";

	return (
		<Layout
			seoTitle="Rekrutierung"
			lang={lang}
			translationPL="/rekrutacja/"
			translationEN="/en/recruitment/"
		>
			<SmallBanner title="Rekrutierung" lang={lang} />

			<section className="recruitment-top">
				<div className="container">
					<div className="row">
						<div className="col-xl-4">
							<h2>Job bei PalettenWerk</h2>
						</div>
						<div className="col-xl-8">
							<p>
								Schließen Sie sich dem Marktführer in der
								Palettenproduktion in Polen an. Wir sind ein
								Unternehmen mit einer etablierten Position auf
								dem Markt, auf dem wir seit über 30 Jahren tätig
								sind. Wir bieten stabile Beschäftigung auf der
								Grundlage eines Arbeitsvertrags, wir investieren
								stets in die Entwicklung des Unternehmens und
								unserer Mitarbeiter. Je nach der Arbeitsstelle
								garantieren wir die Bereitstellung der
								notwendigen Ausrüstung, Gesundheits- und
								Sicherheitsausrüstung sowie die Ausbildung am
								Arbeitsplatz.
							</p>
							<img
								className="img-fluid"
								src={require("../../assets/img/recruitment-image-1.jpg")}
								alt=""
							/>
						</div>
					</div>
				</div>
			</section>

			<section className="recruitment-middle">
				<div className="container">
					<div className="row align-items-end">
						<div className="col-md-4">
							<img
								className="img-fluid"
								src={require("../../assets/img/recruitment-image-2.jpg")}
								alt=""
							/>
						</div>
						<div className="col-md-8">
							<p>
								Unsere Filialen befinden sich an drei
								Standorten. In Jordanów (Woiwodschaft
								Kleinpolen) und Sędziszów (Woiwodschaft
								Heiligkreuz) produzieren wir Holzpaletten, und
								in Krzeszowice bei Krakau werden unter der Marke
								HausWerk vorgefertigte Fertigholzhäuser
								entworfen und hergestellt. Schauen Sie sich
								unsere aktuellen Stellenangebote an und werden
								Sie noch heute Mitglied unseres Teams!
							</p>
						</div>
					</div>
				</div>
			</section>

			<section className="recruitment-grid">
				<div className="container">
					<h2>Aktuell suchen wir nach:</h2>
					<LastThreeRecruitment
						lastRecruitment={lastRecruitment}
						lang={lang}
						slug="rekrutierung"
					/>
				</div>
			</section>
		</Layout>
	);
};

export default Recruitment;

export const query = graphql`
	query {
		lastRecruitment: allWpRecruitment(
			limit: 3
			sort: { fields: date, order: DESC }
			filter: { language: { code: { eq: DE } } }
		) {
			edges {
				node {
					uri
					id
					slug
					title
					excerpt
					language {
						code
						slug
						name
					}
					acfRecruitment {
						recruitmentLocation
						contactInfo
					}
				}
			}
		}
	}
`;
