import React from "react";
import { Link } from "gatsby";

import t from "../locale";

const LastThreeRecruitment = ({ lastRecruitment, lang, slug }) => {
  return (
    <>
      <div className="row justify-content-center">
        {lastRecruitment.edges.map(({ node }) => (
          <div className="col-lg-4 col-sm-6">
            <div className="recruitment-tile">
              <div className="recruitment-tile--header">{node.title}</div>
              <div className="recruitment-tile--location">
                {node.acfRecruitment.recruitmentLocation}
              </div>
              <div
                className="recruitment-tile--desc"
                dangerouslySetInnerHTML={{
                  __html: node.excerpt.substr(0, 150) + "...",
                }}
              />
              <Link
                to={`${lang !== "pl" ? "/" + lang : ""}/${slug}/${node.slug}/`}
                className="btn"
                data-hover={t("Zobacz więcej", lang)}
              >
                {t("Zobacz więcej", lang)}
                <span className="btn-arrows"></span>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
export default LastThreeRecruitment;
